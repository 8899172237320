<template>
  <div class="story">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>
    <!-- <StorySearch/> -->
    <StoryList
      :showArticle="showArticle"/>
    <!-- <Pagination/> -->
    <pagination
      :data="showArticle"
      :item="pagination"
      @pass-val="changeCurrentPage"
    ></pagination>
  </div>
</template>

<script>
import '@/assets/scss/story.scss';
import BannerKv from '@/components/BannerKv.vue';
// import Pagination from '@/components/Pagination.vue';
// import StorySearch from '@/components/story/StorySearch.vue';
import StoryList from '@/components/story/StoryList.vue';
import pagination from '@/components/Paginate.vue';
import { getPageStories } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

// import m01 from '@/assets/img/team/IVF_Center_team/1/1.png';
// import m02 from '@/assets/img/team/IVF_Center_team/1/2.png';
// import m05 from '@/assets/img/team/IVF_Center_team/1/5.png';
// import m20 from '@/assets/img/team/TCM_Center_team/1/1.png';

import pic01 from '@/assets/img/index/story_kv.jpg';

export default {
  name: 'Story',
  components: {
    BannerKv,
    // StorySearch,
    StoryList,
    // Pagination,
    pagination,
  },
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA BABY',
        titleZh: this.$t('nuwaStories'),
        backgroundPic: pic01,
      },
      showArticle: [],
      pagination: {
        currentPageCount: 1,
        totalPageCount: 1,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    changeCurrentPage(val) {
      this.pagination.currentPageCount = val;
      this.showArticle = [];
      console.log(this.$route);
      this.$router.push(`p${val}`);
      this.getData(val);
    },
    getData(currentPage) {
      getPageStories(
        { page: currentPage, language_id: this.getLangUUID },
        (res) => {
          if (res.status) {
            res.data.data.data.forEach((v) => {
              this.showArticle.push({
                pic: v.doctor_image,
                name: v.story_doctor,
                path: `/storyarticle/${v.slug}`,
                date: null,
                title: v.title,
                context: v.content,
              });
            });
            this.pagination = {
              currentPageCount: res.data.data.current_page,
              totalPageCount: res.data.data.last_page,
            };
          }
        },
      );
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        const page = this.$route.params.page.split('p')[1];
        this.getData(parseInt(page, 10));
        console.log(this.$route.params.page.split('p')[1]);
      }
    },
  },
  mounted() {
    if (this.getLangUUID !== null) {
      const page = this.$route.params.page.split('p')[1];
      this.getData(parseInt(page, 10));
      console.log(this.$route.params.page.split('p')[1]);
    }
  },
};
</script>
