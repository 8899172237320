<template>
  <section id="paginate">
    <div class="w1200 container">
      <paginate
        :value="item.currentPageCount"
        :pageCount="item.totalPageCount"
        :page-range="3"
        :containerClass="'page'"
        :active-link-class="'active'"
        :prev-text="''"
        :next-text="''"
        :prev-link-class="'first_last'"
        :next-link-class="'first_last'"
        :clickHandler="linkClose"
      >
      </paginate>
    </div>
  </section>
</template>

<script>
export default {
  name: 'pagination',
  props: ['data', 'item'],
  data() {
    return {
      currentPage: '',
      totalPageCount: '',
    };
  },
  methods: {
    linkClose(page) {
      this.$emit('pass-val', page);
    },
  },
  watch: {
    item(obj) {
      console.log(obj);
      this.currentPage = obj.currentPageCount;
      this.totalPageCount = obj.totalPageCount;
    },
  },
  mounted() {
    console.log(this.item);
  },
};
</script>

<style lang="scss">
@import "../assets/scss/helper/_variable.scss";

.data {
  list-style: none;
}

// 分頁樣式
#paginate {
  .page {
    display: flex;
    justify-content: center;
    list-style: none;
    li {
      a {
        width: 35px;
        height: 35px;
        margin: 0 5px;
        border-radius: 35px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: #636569;
        background-color: transparent;
        transition: 0.3s;
        line-height: 1;
        vertical-align: middle;
        font-size: 1rem;
        padding: 10px;
        outline: none;
        &:hover:not(.active) {
          background-color: #E5A9B9;
          color: #fff;
        }
      }
      &:first-child a {
        background-image: url('../assets/img/arrow-l.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
      &:last-child a {
        background-image: url('../assets/img/arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
      &:first-child a,
      &:last-child a {
        &:hover {
          background-color: $color-theme;
        }
      }
    }
    .active a {
      background-color: #E5A9B9;
      color: #fff;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
    .first_last {
      width: 55px;
      height: 35px;
      margin: 0 5px;
      border-radius: 35px;
      background-color: $color-theme;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      vertical-align: middle;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
